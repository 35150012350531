<template>
  <div
    :class="$style.root"
    :data-within-modal="withinModal ? '' : null"
    v-if="entries?.length"
  >
    <div
      v-if="label"
      :class="$style.label"
      v-html="label"
    />
    <swiper
      @swiper="onSwiper"
      @slideChange="handleOnSlideChange"
      :modules="modules"
      :slidesPerView="'auto'"
      :watchSlidesProgress="true"
      :mousewheel="{
        forceToAxis: true,
        releaseOnEdges: true,
        thresholdDelta: 10
      }"
      :scrollbar="{
        hide: false,
        dragSize: '100px'
      }"
    >
      <SwiperSlide
        v-for="(entry, index) in entries"
        :key="index"
        :class="$style.slide"
         v-slot="{ isVisible }"

      >
        <component
          :is="component"
          :data="entry"
          :linkType="linkType"
          :showClusters="showClusters"
          :linkToPogrammeSlug="entry?.attributes?.permanent_event ? entry?.attributes?.permanent_event === true : null"
          :data-last-slide-visible="isVisible && index === entries.length - 1 ? '' : null"
        />
      </SwiperSlide>
    </swiper>

    <div :class="$style.buttons">
      <button
        :class="$style.button"
        dir="prev"
        :disabled="swiperInstance?.activeIndex == 0 ? '' : null"
        @click="swiperInstance?.slidePrev()"
      >
        <SvgoArrow />
      </button>

      <button
        :class="$style.button"
        dir="next"
        :disabled="swiperInstance?.activeIndex === slidesCount - 1 ? '' : null"
        @click="swiperInstance?.slideNext()"
      >
        <SvgoArrow />
      </button>
    </div>
  </div>
</template>

<script>
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/vue'

import 'swiper/css/scrollbar';
import 'swiper/css/effect-coverflow';
import { Scrollbar, Mousewheel } from 'swiper/modules';

export default {
  props: {
    entries: {
      type: Array,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    component: {
      type: Object,
      required: true
    },
    linkType: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'dynamic'].includes(value)
    },
    showClusters: {
      type: Boolean,
      default: true,
    },
    withinModal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const swiperInstance = ref(null)
    const onSwiper = (swiper) => {
      swiperInstance.value = swiper
    }

    return {
      onSwiper,
      swiperInstance,
      modules: [Scrollbar, Mousewheel]
    }
  },
  computed: {
    slidesCount() {
      return this.swiperInstance?.slides?.length
    },
    sliderWidth () {
      return this.swiperInstance?.width
    }
  },
  mounted() {
    if (this?.slidesCount === 1) {
      this.swiperInstance.disable()
    }
  },
  beforeUnmount() {
    this.swiperInstance?.destroy()
  },
  methods: {
    handleOnSlideChange() {
      this.$emit('set-active-index', this.swiperInstance?.activeIndex)
    }
  }
}
</script>

<style module lang="scss">
.root {
  --card--width: min(var(--width--card), 75vw);

  position: relative;
}

.root[data-within-modal] {
  --card--width: min(var(--width--card--modal), 75vw);
}

.label {
  composes: font-size-medium from global;
  margin-bottom: var(--unit--vertical);
  padding-left: var(--unit--horizontal);
  padding-right: var(--unit--horizontal);
}

.label sup {
  color: var(--color--gray--mid);
}

.root[data-within-modal] .label {
  padding-left: calc(var(--unit--horizontal) * 2);
  padding-right: calc(var(--unit--horizontal) * 2);
}

.slide {
  width: auto !important;
}

.slide {
  padding-left: var(--unit--horizontal);
}

.root[data-within-modal] .slide {
  padding-left: calc(var(--unit--horizontal) * 2);
}

.slide:last-child {
  padding-right: var(--unit--horizontal);
}

.root[data-within-modal] .slide:last-child {
  padding-right: calc(var(--unit--horizontal) * 2);
}

.button {
  composes: reset-button button-round from global;
}

.button[disabled] {
  --button--background-color: var(--color--gray--mid);
  --button--background-color--hover: var(--color--gray--mid);
}

.button[dir='next'] svg {
  transform: rotate(180deg);
}
.root:has( a[data-last-slide-visible] ) .button[dir='next'] {
  --button--background-color: var(--color--gray--mid);
  --button--background-color--hover: var(--color--gray--mid);
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: var(--unit--spacer);
  padding: 0 var(--unit--horizontal);

  margin-top: var(--unit--vertical);
}

.root:has(div[class*='swiper-scrollbar-lock']) .buttons {
  display: none;
}

.root[data-within-modal] .buttons {
  padding: 0 calc(var(--unit--horizontal) * 2);
}
</style>
